import "./WelcomeBlock.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const WelcomeBlock = (props) => {
    const {welcome, contacts, social} = props;
    return (
        <section id="welcome" className="welcome-block">

            <div className="welcome-block__background">
                <div className="welcome-block__background-img">
                    <img src="/welcome.png" alt="" draggable="false"/>
                </div>
            </div>

            <div className="welcome-block__container">
                <div className="welcome-block__bottom">
                    <div className="welcome-block__slogan">
                        <span>{welcome?.slogan}</span>
                    </div>
                    <div className="welcome-block__sign-up">
                        <a href={welcome?.signUpLink}>Записатися</a>
                    </div>
                    <div className="welcome-block__contacts">
                        <div className="welcome-block__contacts-addresses">
                            {contacts?.map((val, i) => <span key={i}>{val?.address}</span>)}
                        </div>
                        <div className="welcome-block__contacts-phones">
                            {contacts?.map((val, i) => <span key={i}>{val?.phone}</span>)}
                        </div>
                        <div className="welcome-block__contacts-social">
                            {social?.map((val, i) =>
                                <a key={i} href={val?.link}>
                                    {!!val?.icon ? <FontAwesomeIcon className="icon" icon={"" + val?.icon}/> : ''}
                                    <span className="text">{val?.label}</span>
                                </a>
                            )}
                        </div>
                    </div>
                </div>

            </div>

        </section>
    );
}

export default WelcomeBlock;
